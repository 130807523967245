"use client";
import oopsImage from "@/../public/images/oops.svg";
import JDSButtons from "@/Component/InputFields/JDSButtons/JDSButtons";
import { COLORS } from "@/SupportingFiles/colors";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Image from "next/image";

function PageNotFound() {
  return (
    <Box padding="24px">
      <Grid
        container
        spacing={3}
        alignItems="center"
        minHeight="calc(100vh - 100px)"
      >
        <Grid size={{ xs: 12, md: 6 }} textAlign="left">
          <h2 style={{ textAlign: "left", marginBottom: "24px" }}>
            Oops! Page Not Found.
          </h2>
          <p style={{ color: COLORS.lightgrey }}>
            Looks like you took a wrong turn. But don't worry, we're here to
            help! Head back to the homepage.
          </p>
          <JDSButtons
            onClick={() => {
              window.location.href =
                "/?utm_source=website404&utm_medium=404hplink&utm_campaign=404hpclick";
            }}
            className="primaryBtns large mt-4"
            text="Go To Homepage"
            variant="text"
            fullWidth={false}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <img
            src={oopsImage}
            width={"50"}
            height={"50"}
            alt="img"
            unoptimized={true}
            loading="lazy"
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default PageNotFound;
